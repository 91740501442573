import React from "react";
import Sebrae from "../assets/imgs/sebrae.png";

const BcxSectionD = () => {
  return (
    <section id="sectionD">
      <div className="container">
        <div className="BcxSectionD">
          <div className="descricao">
            <img src={Sebrae} alt="Logo da Sebrae" />
            <h2>
              Uma Parceria <br />
              <span>
                com soluções para
                <br />
                sua empresa
              </span>
            </h2>
            <p>
              O Sebrae está oferecendo 2 capacitações online{" "}
              <strong>GRATUITAS</strong> aos interessados.
            </p>
            <p>
              Mesmo sem efetivação do crédito, você pode ter acesso a
              ferramentas que te ajudam a gerenciar melhor seus negócios.
            </p>
            <p>
              <strong>Aproveite essa oportunidade!</strong>
            </p>
          </div>

          <div className="content">
            <h3>Finanças</h3>
            <a
              className="btn"
              href="https://digital.sebraers.com.br/produtos/financas/curso/curso-online-credi2irmaos/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Finanças empresariais: controlar para expandir
            </a>

            <h3>Marketing e Vendas</h3>
            <a
              className="btn"
              href="https://digital.sebraers.com.br/produtos/marketing-e-vendas/curso/marketing-digital-direto-ao-ponto/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Marketing Digital Direto ao Ponto
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BcxSectionD;
