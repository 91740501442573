import React from "react";
import logoCred from "../assets/imgs/logocred.png";
import logosB from "../assets/imgs/logos_lng.png";

const BcxSectionA = () => {
  return (
    <section id="SectionA">
      <div className="container">
        <div className="BcxSectionA">
          <div className="text">
            <p>
              O Município de Dois Irmãos possui um potencial econômico
              significativo, com uma diversidade de setores em crescimento, como
              agricultura, indústria e comércio.
            </p>
            <p>
              O Credi2Irmãos tem como objetivo impulsionar ainda mais esses
              setores, oferecendo acesso a linhas de crédito para empreendedores
              locais, possibilitando o crescimento de seus negócios e a criação
              de novos empregos.
            </p>
            <p>
              Ao apoiar o crescimento econômico de Dois Irmãos, o programa
              contribui para o equilíbrio regional, isso atrai investimentos,
              melhora a infraestrutura e a qualidade de vida dos habitantes
              locais.
            </p>
            <p>
              O Credi2Irmãos é resultado de uma união entre a Prefeitura de Dois
              Irmãos, o Sebrae, o Sicredi e a RS Garanti, como a garantidora de
              crédito.
            </p>
          </div>

          <div className="logos">
            <img src={logoCred} alt="Logo Credi2Irmãos" className="logocred" />
            <img
              className="parceiros"
              src={logosB}
              alt="Sicredi, Sebrae, RS Garanti, Prefeitura de Dois Irmãos"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BcxSectionA;
