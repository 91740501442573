import React from "react";
import card1 from "../assets/imgs/ico-1.png";
import card2 from "../assets/imgs/ico-2.png";
import card3 from "../assets/imgs/ico-3.png";

const BcxSectionB = () => {
  return (
    <section id="sectionB">
      <div className="container">
        <div className="BcxSectionB">
          <h2 className="title">
            Soluções em crédito <br />
            <span>para a sua empresa</span>
          </h2>
          <div className="cards">
            <div className="card">
              <img src={card1} alt="Icone Item 1" />
              <h3>
                Crédito para
                <br />
                <strong>qualquer necessidade</strong>
              </h3>
            </div>
            <div className="card">
              <img src={card2} alt="Icone Item 2" />
              <h3>
                Condições
                <br />
                <strong>exclusivas</strong>
              </h3>
            </div>
            <div className="card">
              <img src={card3} alt="Icone Item 3" />
              <h3>
                Valor disponível
                <br />
                <strong>em poucos dias</strong>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BcxSectionB;
